import type {
  CombinedError,
  OperationResult,
  TypedDocumentNode,
  UseMutationResponse,
} from 'urql'
import { useMutation } from 'urql'
import { useCallback, useMemo } from 'react'
import type { DocumentNode } from 'graphql'
import type { Operation } from '@urql/core'

export type UrqlMutationResult<Data = any, Variables = object> = UseMutationResponse<
  Data,
  Variables
>[0]
export type UrqlMutateFn<Data = any, Variables = object> = UseMutationResponse<
  Data,
  Variables
>[1]

export type EnhancedMutate<Data = any, Variables = object> = (
  ...args: Parameters<UrqlMutateFn<Data, Variables>>
) => Promise<UrqlOperationResultStates<Data, Variables>>

function useGetUrqlMemoizedStates<Data = any, Variables = object>(
  state: UrqlMutationResult<Data, Variables>,
) {
  const memoizedIdleState = useMemo<IdleState>(() => ({ state: 'idle' }), [])

  const memoizedPartialState = useMemo<PartialState<Data, Variables>>(
    () => ({
      state: 'partial',
      error: state.error!,
      data: state.data!,
      operation: state.operation!,
      stale: false,
    }),
    [state.data, state.error, state.operation],
  )

  const memoizedPartialStaleState = useMemo<PartialStaleState<Data, Variables>>(
    () => ({
      state: 'partial-stale',
      error: state.error!,
      data: state.data!,
      operation: state.operation!,
      stale: true,
    }),
    [state.data, state.error, state.operation],
  )

  const memoizedErrorState = useMemo<ErrorState<Data, Variables>>(
    () => ({
      error: state.error!,
      state: 'error',
      operation: state.operation!,
      data: null,
    }),
    [state.error, state.operation],
  )

  const memoizedFetchingState = useMemo<FetchingState<Data, Variables>>(
    () => ({ state: 'fetching', operation: state.operation! }),
    [state.operation],
  )

  const memoizeStale = useMemo<StaleState<Data, Variables>>(
    () => ({
      data: state.data!,
      stale: true,
      state: 'stale',
      operation: state.operation!,
    }),
    [state.data, state.operation],
  )

  const memoizedDoneState = useMemo<DoneState<Data, Variables>>(
    () => ({
      data: state.data!,
      stale: false,
      state: 'done',
      operation: state.operation!,
    }),
    [state.data, state.operation],
  )
  return {
    memoizedIdleState,
    memoizedPartialStaleState,
    memoizedPartialState,
    memoizedErrorState,
    memoizedFetchingState,
    memoizeStale,
    memoizedDoneState,
  }
}

const isInitialState = <Data = any, Variables = object>(
  state: UrqlMutationResult<Data, Variables>,
) => {
  return (
    !state.fetching && !state.operation && !state.data && !state.stale && !state.error
  )
}

export const useStatefulMutation = <Data = any, Variables = object>([
  state,
  mutate,
]: UseMutationResponse<Data, Variables>): [
  UrqlStates<Data, Variables>,
  EnhancedMutate<Data, Variables>,
] => {
  const {
    memoizedIdleState,
    memoizedPartialState,
    memoizedPartialStaleState,
    memoizeStale,
    memoizedDoneState,
    memoizedErrorState,
    memoizedFetchingState,
  } = useGetUrqlMemoizedStates(state)

  type StatefulMutate = EnhancedMutate<Data, Variables>
  const mutateStateful = useCallback<StatefulMutate>(
    (...args: Parameters<UrqlMutateFn<Data, Variables>>) => {
      return (mutate as UrqlMutateFn<Data, Variables>)(...args).then((response) =>
        buildStateFromResponse(response),
      )
    },
    [mutate],
  )

  const memoizedIdleStateWithMutate = useMemo<[IdleState, StatefulMutate]>(
    () => [memoizedIdleState, mutateStateful],
    [memoizedIdleState, mutateStateful],
  )

  const memoizedPartialStateWithMutate = useMemo<
    [PartialState<Data, Variables>, StatefulMutate]
  >(() => [memoizedPartialState, mutateStateful], [memoizedPartialState, mutateStateful])

  const memoizedPartialStaleStateWithMutate = useMemo<
    [PartialStaleState<Data, Variables>, StatefulMutate]
  >(() => [memoizedPartialStaleState, mutateStateful], [
    memoizedPartialStaleState,
    mutateStateful,
  ])

  const memoizeStaleWithMutate = useMemo<[StaleState<Data, Variables>, StatefulMutate]>(
    () => [memoizeStale, mutateStateful],
    [memoizeStale, mutateStateful],
  )

  const memoizedDoneStateWithMutate = useMemo<
    [DoneState<Data, Variables>, StatefulMutate]
  >(() => [memoizedDoneState, mutateStateful], [memoizedDoneState, mutateStateful])

  const memoizedErrorStateWithMutate = useMemo<
    [ErrorState<Data, Variables>, StatefulMutate]
  >(() => [memoizedErrorState, mutateStateful], [memoizedErrorState, mutateStateful])

  const memoizedFetchingStateWithMutate = useMemo<
    [FetchingState<Data, Variables>, StatefulMutate]
  >(() => [memoizedFetchingState, mutateStateful], [
    memoizedFetchingState,
    mutateStateful,
  ])

  if (isInitialState(state)) {
    return memoizedIdleStateWithMutate
  }

  if (state.error && state.data && state.stale) {
    return memoizedPartialStaleStateWithMutate
  }

  if (state.fetching) {
    return memoizedFetchingStateWithMutate
  }

  if (state.error && state.data === null) {
    return memoizedErrorStateWithMutate
  }

  if (state.error && state.data) {
    return memoizedPartialStateWithMutate
  }

  if (state.error) {
    return memoizedErrorStateWithMutate
  }

  if (state.stale && state.data) {
    return memoizeStaleWithMutate
  }

  if (state.data) {
    return memoizedDoneStateWithMutate
  }
  throw Error('This is impossible state!')
}

export type UrqlStates<Data = any, Variables = object> =
  | FetchingState<Data, Variables>
  | DoneState<Data, Variables>
  | ErrorState<Data, Variables>
  | IdleState
  | StaleState<Data, Variables>
  | PartialState<Data, Variables>
  | PartialStaleState<Data, Variables>

export type FetchingState<Data = any, Variables = object> = {
  state: 'fetching'
  operation?: Operation<Data, Variables>
}

export type DoneState<Data = any, Variables = object> = {
  state: 'done'
  data: Data
  stale: false
  operation: Operation<Data, Variables>
}

export type StaleState<Data = any, Variables = object> = {
  state: 'stale'
  data: Data
  stale: true
  operation: Operation<Data, Variables>
}

export type IdleState = {
  state: 'idle'
}

export type ErrorState<Data = any, Variables = object> = {
  state: 'error'
  error: CombinedError
  data: null
  operation: Operation<Data, Variables>
}

export type PartialState<Data = any, Variables = object> = {
  state: 'partial'
  error: CombinedError
  data: Data
  stale: false
  operation: Operation<Data, Variables>
}

export type PartialStaleState<Data = any, Variables = object> = {
  state: 'partial-stale'
  error: CombinedError
  data: Data
  stale: true
  operation: Operation<Data, Variables>
}

export type UrqlOperationResultStates<Data, Variables> =
  | DoneState<Data, Variables>
  | ErrorState<Data, Variables>
  | PartialState<Data, Variables>

const buildStateFromResponse = <Data = any, Variables = object>(
  response: OperationResult<Data, Variables>,
): UrqlOperationResultStates<Data, Variables> => {
  const { data, error, operation } = response

  if (error && data) {
    return { state: 'partial', error, data, stale: false, operation }
  }

  if (error) {
    return { state: 'error', error, operation, data: null }
  }

  if (data) {
    return { state: 'done', data, stale: false, operation }
  }
  throw Error('Impossible state!')
}

/**
 * Returns array of 4 elements.
 * 0 - enriched original data wrapped into state machine
 * 1 - wrapped mutate function that returns promise with response wrapped in state machine
 * 2 - original data without any wrapper
 * 3 - original mutate promise without any wrapper
 */
export const useEnhancedMutation = <Data = any, Variables = object>(
  query: DocumentNode | TypedDocumentNode<Data, Variables> | string,
): [
  UrqlStates<Data, Variables>,
  EnhancedMutate<Data, Variables>,
  UrqlMutationResult<Data, Variables>,
  UrqlMutateFn<Data, Variables>,
] => {
  const originalMutationResult = useMutation(query)
  const [originalData, originalMutate] = originalMutationResult
  const [statefulData, statefulMutate] = useStatefulMutation(originalMutationResult)
  return useMemo(() => {
    return [statefulData, statefulMutate, originalData, originalMutate]
  }, [statefulData, statefulMutate, originalData, originalMutate])
}
