import { isPlainObject, omit } from 'lodash'
import type { OmitRecursively } from './type-utils'

import type { UseQueryState } from 'urql'
import type { MarkRequired } from 'ts-essentials'

function omitTypenameRecursively<T extends {}>(obj: T) {
  const withoutTypename = omit(obj, ['__typename'])

  return Object.entries(withoutTypename).reduce((accum: any, [key, value]) => {
    if (isPlainObject(value)) {
      accum[key] = omitTypenameRecursively(value as object)
    } else if (Array.isArray(value)) {
      accum[key] = value.map((item) =>
        isPlainObject(item) ? omitTypenameRecursively(item) : item,
      )
    } else {
      accum[key] = value
    }

    return accum
  }, {})
}

/**
 * Degraphql means remove all nested `__typename` fields inside the data
 * that GraphQL Clients using for their cache to work.
 */
export function degraphqlData<T extends { __typename?: string }>(
  obj: T,
): OmitRecursively<T, '__typename'>
export function degraphqlData<T extends { __typename?: string }>(
  obj: T[],
): OmitRecursively<T, '__typename'>[]
export function degraphqlData<T extends { __typename?: string }>(obj: T | T[]) {
  if (Array.isArray(obj)) {
    return obj.map(omitTypenameRecursively)
  }
  return omitTypenameRecursively(obj)
}

export type SuspendedUseQueryState<Data = any, Variables = object> = MarkRequired<
  UseQueryState<Data, Variables>,
  'data'
>

export const isValidResult = <Data = any, Variables = object>(
  urqlResult: UseQueryState<Data, Variables>,
): urqlResult is SuspendedUseQueryState<Data, Variables> => {
  if (urqlResult?.error && urqlResult?.data == null) {
    return false
  }
  return true
}
