import React from 'react'
import { Box } from '@chakra-ui/react'
import { ThemeToggleButton } from './ThemeToggleButton'

export const FixedThemeToggleButton = () => {
  return (
    <Box position="fixed" left={4} top={4}>
      <ThemeToggleButton />
    </Box>
  )
}
