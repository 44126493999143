import React from 'react'
import type { ButtonProps } from '@chakra-ui/react'
import { Button, useColorMode } from '@chakra-ui/react'
import { FaMoon, FaSun } from 'react-icons/fa'

export const ThemeToggleButton = (props: ButtonProps) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const isLight = colorMode === 'light'
  return (
    <Button pl={2} pr={2} onClick={toggleColorMode} fontSize="lg" {...props}>
      {isLight ? <FaMoon /> : <FaSun />}
    </Button>
  )
}
