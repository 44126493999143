import type { FlexProps } from '@chakra-ui/react'
import { Stack, Flex, Text, Box } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import React from 'react'

export const GenericEmpty = ({
  message,
  children,
  ...rest
}: FlexProps & {
  message?: ReactNode
  children?: ReactNode
}) => {
  return (
    <Flex
      w="100%"
      h="100%"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      p={4}
      {...rest}
    >
      {children ?? (
        <Stack space={2}>
          {message ?? (
            <Box>
              <Text>No results to show</Text>
            </Box>
          )}
        </Stack>
      )}
    </Flex>
  )
}
