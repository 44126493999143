import { useEffect } from 'react'
import { useColorMode } from '@chakra-ui/react'

export const useListenToSystemTheme = () => {
  const { setColorMode } = useColorMode()

  useEffect(() => {
    const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    try {
      // Chrome & Firefox
      darkMediaQuery.addEventListener('change', (e) => {
        const systemColorMode = e.matches ? 'dark' : 'light'
        setColorMode(systemColorMode)
      })
    } catch (e1) {
      try {
        // Safari
        darkMediaQuery.addListener((e) => {
          const systemColorMode = e.matches ? 'dark' : 'light'
          setColorMode(systemColorMode)
        })
      } catch (e2) {
        console.error(e2)
      }
    }
  })
}
