import { AUTH_CHANGE_EVENT, AuthService } from '@liveflow-io/service-auth'
import { Routes } from 'packlets/constants'

// @ts-expect-error
const Auth: AuthService =
  process.env.NODE_ENV !== 'test' &&
  new AuthService(
    process.env.REACT_APP_AUTH0_CLIENT_ID,
    process.env.REACT_APP_AUTH0_DOMAIN_ADDRESS,
    'https://backoffice-graphql/',
    Routes.SIGN_IN,
  )

export { Auth as AuthService, AUTH_CHANGE_EVENT }
