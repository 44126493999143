import React from 'react'
import { GenericEmpty } from '@liveflow-io/component-common'
import { Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react'
import type { Company } from 'packlets/generated'
import { Routes } from 'packlets/constants'
import { useNavigate } from 'react-router'

type CompaniesTableProps = {
  data: Pick<
    Company,
    | 'city'
    | 'country'
    | 'createdDate'
    | 'email'
    | 'id'
    | 'name'
    | 'postCode'
    | 'state'
    | 'streetLine1'
    | 'streetLine2'
  >[]
}

export const CompaniesTable = ({ data }: CompaniesTableProps) => {
  const hoverBg = useColorModeValue('gray.50', 'gray.700')
  const navigate = useNavigate()
  if (data.length === 0) {
    return <GenericEmpty />
  }
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Email</Th>
          <Th>Country</Th>
          <Th>Created date</Th>
          <Th>City</Th>
          <Th>Post code</Th>
          <Th>State</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((company) => {
          return (
            <Tr
              onClick={() => navigate(`${Routes.COMPANY}/${company.id}`)}
              key={company.id}
              _hover={{
                transition: 'all 0.2s',
                bg: hoverBg,
                cursor: 'pointer',
              }}
            >
              <Td>{company.name}</Td>
              <Td>{company.email}</Td>
              <Td>{company.country}</Td>
              <Td>{company.createdDate}</Td>
              <Td>{company.city}</Td>
              <Td>{company.postCode}</Td>
              <Td>{company.state}</Td>
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}
