import React from 'react'
import type { HTMLChakraProps } from '@chakra-ui/react'
import { chakra, useColorModeValue, forwardRef } from '@chakra-ui/react'

export const Card = forwardRef<HTMLChakraProps<'div'>, 'div'>(
  ({ title, children, ...rest }, ref) => (
    <chakra.div
      py="6"
      px="8"
      rounded="lg"
      bg={useColorModeValue('white', 'gray.700')}
      shadow="base"
      overflow="hidden"
      ref={ref}
      {...rest}
    >
      {children}
    </chakra.div>
  ),
)
