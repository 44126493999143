export const formatNumber = (
  number: number,
  options?: Intl.NumberFormatOptions,
  locale?: string,
) => new Intl.NumberFormat(locale, options).format(number)

const formatMoneyDefaultOptions = {
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
}
export const formatMoney = (
  number: number | bigint,
  options?: Intl.NumberFormatOptions,
  locale?: string,
) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    ...formatMoneyDefaultOptions,
    ...options,
  }).format(number)

export type Money = { amount: number | bigint; currency: string }

export const renderMoney = (
  money: Money,
  options?: Intl.NumberFormatOptions,
  locale?: string,
) => {
  return formatMoney(
    money.amount,
    {
      currency: money.currency,
      ...options,
    },
    locale,
  )
}

export const renderMoneyOrNA = (num?: Money | null) => (num ? renderMoney(num) : 'N/A')
