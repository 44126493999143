import { useToast as useChakraToast } from '@chakra-ui/react'
import { useMemo } from 'react'
import type { UseToastOptions } from '@chakra-ui/react'

export const useToast = (options?: UseToastOptions) => {
  const defaultOptions = useMemo(() => {
    return {
      position: 'top-right' as UseToastOptions['position'],
      status: 'success' as UseToastOptions['status'],
      isClosable: true,
      ...(options ?? {}),
    }
  }, [options])
  const toast = useChakraToast(defaultOptions)
  // This will cause toasts to be improperly colored before person refreshes page / remounts
  // component, but will help to avoid stupid `useEffect` unstable reference bugs that are way more dangerous
  // than broken styling :)
  // eslint-disable-next-line
  return useMemo(() => toast, [])
}
