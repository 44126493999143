import React, { Suspense, useCallback, useState } from 'react'
import { Navigate, useParams } from 'react-router'
import { Routes } from 'packlets/constants'
import type { Company_CompanyQuery } from './documents.generated'
import { UserAttachUserModal_UsersDocument } from './documents.generated'
import {
  Company_CompanyDocument,
  Company_AddChangeSubscriptionPlanDocument,
  Company_SubscriptionPlansDocument,
  UsersTable_DetachUser_CompanyDocument,
  UserAttachUserModal_AttachUser_CompanyDocument,
} from './documents.generated'
import { useEnhancedMutation, useToast } from '@liveflow-io/hooks-common'
import {
  Card,
  GenericEmpty,
  GenericError,
  GenericSpinner,
  LabeledInput,
} from '@liveflow-io/component-common'
import {
  formatMoney,
  impossibleState,
  pruneEmpty,
  renderMoney,
  utcDayJs,
  isNotEmptyOrNullish,
  isEmptyOrNullish,
  isValidResult,
} from '@liveflow-io/utils-common'
import {
  Box,
  Button,
  Code,
  Collapse,
  Heading,
  Modal,
  SlideFade,
  Stack,
  Table,
  Tbody,
  Td,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  Tfoot,
  Skeleton,
} from '@chakra-ui/react'
import { useQuery } from 'urql'
import { atom, useAtom } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'
import { useDebounce } from 'react-use'

export const CompanyPage = () => {
  const { companyId } = useParams()
  if (isEmptyOrNullish(companyId)) {
    return <Navigate to={Routes.SEARCH} />
  }

  return (
    <Suspense
      fallback={
        <GenericSpinner size="xl" containerProps={{ minH: '500px', h: 'full' }} />
      }
    >
      <Company companyId={companyId} />
    </Suspense>
  )
}

type Companies = NonNullable<Company_CompanyQuery['company']>

type Transactions = Companies['transactions']
type Users = Companies['users']
type Budgets = Companies['budgets']
type BankAccounts = Companies['bankAccounts']
type Integrations = Companies['integrations']

const Company = ({ companyId }: { companyId: string }) => {
  const [companyResult] = useQuery({
    query: Company_CompanyDocument,
    pause: isEmptyOrNullish(companyId),
    variables: {
      id: companyId,
    },
  })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [documentsQuery] = useQuery({
    query: Company_SubscriptionPlansDocument,
  })
  const [, addOrChangePlan] = useEnhancedMutation(
    Company_AddChangeSubscriptionPlanDocument,
  )

  const onAddOrChangePlan = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      const subscriptionPlanId = e.currentTarget.dataset.subscriptionPlanId
      const aCompanyId = companyResult?.data?.company?.id
      onClose()
      if (isNotEmptyOrNullish(subscriptionPlanId) && isNotEmptyOrNullish(aCompanyId))
        addOrChangePlan({
          subscriptionPlanId,
          companyId: aCompanyId,
        })
          .then((res) => {
            switch (res.state) {
              case 'partial':
              case 'error':
                toast({
                  status: 'error',
                  description: res.error.message,
                })
                break
              case 'done':
                toast({
                  title: 'Success!',
                  description: 'Plan has been successfully added to company!',
                })
                break
              default:
                impossibleState(res)
            }
            return res
          })
          .catch(console.error)
    },
    [addOrChangePlan, companyResult?.data?.company?.id, onClose, toast],
  )

  if (!isValidResult(companyResult)) {
    return <GenericError />
  }

  if (!companyResult.data.company) {
    return <GenericEmpty />
  }

  const company = companyResult.data.company
  const price = company.subscriptionPlan?.subscriptionPlan.price
  return (
    <SlideFade in>
      <Stack spacing={2}>
        <Heading>{company.name}</Heading>
        <Stack spacing={16}>
          <Stack direction="row" spacing={16}>
            <Stack>
              <Box>
                <Text>ID: {company.id}</Text>
                <Text>Name: {company.name}</Text>
                <Text>E-mail: {company.email}</Text>
                <Text>
                  Created date: {utcDayJs(company.createdDate).formatDateTime()}
                </Text>
                <Text>
                  Updated date: {utcDayJs(company.updatedDate).formatDateTime()}
                </Text>
                <Text>Postcode: {company.postCode}</Text>
                <Text>State: {company.state}</Text>
              </Box>
              <Box>
                <Heading size="md">Subscription</Heading>
                <Text>Price: {price ? renderMoney(price) : 'N/A'}</Text>
                <Button onClick={onOpen}>Add/Change subscription</Button>
              </Box>
            </Stack>
            <Box flex="1 1">
              <Heading size="md">Users</Heading>
              <UsersTable data={company.users} />
            </Box>
            <Box flex="1 1">
              <Heading size="md">Budgets</Heading>
              <BudgetsTable data={company.budgets} />
            </Box>
          </Stack>
          <Stack direction="row" spacing={16}>
            <Box flex="1 1">
              <Heading size="md">Integrations</Heading>
              <IntegrationsTable data={company.integrations} />
            </Box>
            <Box flex="1 1">
              <Heading size="md">BankAccounts</Heading>
              <BankAccountsTable data={company.bankAccounts} />
            </Box>
          </Stack>
          <Stack direction="row" spacing={16}>
            <Box flex="1 1">
              <Heading size="md">Transactions</Heading>
              <TransactionsTable data={company.transactions} />
            </Box>
          </Stack>
        </Stack>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Available plans</ModalHeader>
            <ModalCloseButton />
            <ModalBody as={Stack} direction="row" flexWrap="wrap" spacing={6}>
              {documentsQuery.data?.subscriptionPlans
                .filter(
                  (plan) => plan.id !== company.subscriptionPlan?.subscriptionPlan.id,
                )
                .map((plan) => {
                  return (
                    <Card
                      key={plan.id}
                      data-subscription-plan-id={plan.id}
                      onClick={onAddOrChangePlan}
                      textAlign="center"
                      flex="1 1"
                      sx={{
                        userSelect: 'none',
                        cursor: 'pointer',
                        transition: 'transform 0.2s',
                      }}
                      _hover={{ transform: 'scale(1.03)' }}
                      _active={{ transform: 'scale(1.00)' }}
                    >
                      <Text fontSize="2xl">{renderMoney(plan.price)}</Text>
                    </Card>
                  )
                })}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </SlideFade>
  )
}

const JsonReveal = ({ object }: { object: object }) => {
  const { isOpen, onToggle } = useDisclosure()
  return (
    <Stack>
      <Button size="sm" onClick={onToggle}>
        Show
      </Button>
      <Collapse in={isOpen}>
        <pre>
          <Code>{JSON.stringify(pruneEmpty(object), null, 2)}</Code>
        </pre>
      </Collapse>
    </Stack>
  )
}

function paginate<T>(array: T[], pageSize: number, pageNumber: number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

const TransactionsTable = ({ data }: { data: Transactions }) => {
  const [page, setPage] = useState<number>(1)
  const incomeBg = useColorModeValue('green.100', 'green.900')
  const expenseBg = useColorModeValue('red.100', 'red.900')
  if (data.length === 0) {
    return <GenericEmpty />
  }
  const pageSize = 25
  const pages = Math.ceil(data.length / pageSize)
  const pageSet = paginate(data, pageSize, page)
  return (
    <Table mt={4} size="sm">
      <Thead>
        <Tr>
          <Td colSpan={5}>
            <Stack justifyContent="center" alignItems="center">
              <Text>Page</Text>
              <Stack direction="row" justifyContent="center" flexWrap="wrap">
                {Array(pages)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <Button
                        size="sm"
                        variant="ghost"
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        onClick={() => setPage(index + 1)}
                        disabled={index + 1 === page}
                      >
                        {index + 1}
                      </Button>
                    )
                  })}
              </Stack>
            </Stack>
          </Td>
        </Tr>
        <Tr>
          <Th>Amount</Th>
          <Th>Origin date</Th>
          <Th>Source</Th>
          <Th>Body</Th>
        </Tr>
      </Thead>
      <Tbody>
        {pageSet.map((tx) => (
          <Tr key={tx.id} bg={tx.type === 'INCOME' ? incomeBg : expenseBg}>
            <Td>{formatMoney(tx.amount)}</Td>
            <Td>{utcDayJs(tx.originDate).formatYyyyMmDd()}</Td>
            <Td>{tx.source}</Td>
            <Td>
              <JsonReveal object={tx.body} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const IntegrationsTable = ({ data }: { data: Integrations }) => {
  if (data.length === 0) {
    return <GenericEmpty />
  }
  return (
    <Table mt={4} size="sm">
      <Thead>
        <Tr>
          <Th>id</Th>
          <Th>source</Th>
          <Th>created date</Th>
          <Th>metadata</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((integration) => (
          <Tr key={integration.id}>
            <Td>{integration.id}</Td>
            <Td>{integration.source}</Td>
            <Td>{utcDayJs(integration.createdDate).formatDateTime()}</Td>
            <Td>
              <JsonReveal object={integration.metadata} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const isAttachUserModalOpenAtom = atom<boolean>(false)

const MultirowSkeleton = (
  <Stack spacing={2}>
    <Skeleton h="40px" />
    <Skeleton h="40px" />
    <Skeleton h="40px" />
    <Skeleton h="40px" />
    <Skeleton h="40px" />
  </Stack>
)

const AttachUserModal = () => {
  const [search, setSearch] = useState('@liveflow.io')
  const [debouncedSearch, setDebouncedSearch] = useState('@liveflow.io')
  const [isOpen, setIsOpen] = useAtom(isAttachUserModalOpenAtom)
  useDebounce(
    () => {
      setDebouncedSearch(search)
    },
    600,
    [search],
  )
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Attach user</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} flexWrap="wrap" spacing={4} minH="400px">
          <LabeledInput
            label="Search user:"
            placeholder="Input email, name or whatever about the user"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
          <Suspense fallback={MultirowSkeleton}>
            <UsersToAttach search={debouncedSearch} />
          </Suspense>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const UsersToAttach = ({ search }: { search: string }) => {
  const [, attachUser] = useEnhancedMutation(
    UserAttachUserModal_AttachUser_CompanyDocument,
  )
  const [usersResult] = useQuery({
    query: UserAttachUserModal_UsersDocument,
    pause: search === '',
    variables: {
      search,
    },
  })

  const { companyId } = useParams()
  const toast = useToast()
  const setIsOpen = useUpdateAtom(isAttachUserModalOpenAtom)
  const onClose = useCallback(() => setIsOpen(false), [setIsOpen])

  const onAttachUser = useCallback(
    (userId: string) => {
      attachUser({
        userId,
        companyId,
      })
        .then((res) => {
          switch (res.state) {
            case 'partial':
            case 'error':
              toast({
                status: 'error',
                description: res.error.message,
              })
              break
            case 'done':
              toast({
                title: 'Success!',
                description: 'User has been attached successfully!',
              })
              void onClose()
              break
            default:
              impossibleState(res)
          }
          return res
        })
        .catch(console.error)
    },
    [onClose, toast, attachUser, companyId],
  )

  if (!isValidResult(usersResult)) {
    return <GenericError />
  }

  return (
    <Stack spacing={2}>
      {usersResult.data.users
        .filter((user) => user.company?.id !== companyId)
        .map((user) => {
          return (
            <Card
              key={user.id}
              onClick={() => onAttachUser(user.id)}
              flex="1 1"
              sx={{
                userSelect: 'none',
                cursor: 'pointer',
                transition: 'transform 0.2s',
                py: 2,
                px: 4,
              }}
              _hover={{ transform: 'scale(1.03)' }}
              _active={{ transform: 'scale(1.00)' }}
            >
              <Text>
                {user.firstName}, {user.email}, Current company:{' '}
                {user.company?.name ?? 'No company'}
              </Text>
            </Card>
          )
        })}
    </Stack>
  )
}

const UsersTable = ({ data }: { data: Users }) => {
  const [, detachUser] = useEnhancedMutation(UsersTable_DetachUser_CompanyDocument)
  const { companyId } = useParams()
  const toast = useToast()
  const setAttachUserModal = useUpdateAtom(isAttachUserModalOpenAtom)
  const onOpenAttachUserModal = useCallback(() => {
    void setAttachUserModal(true)
  }, [setAttachUserModal])

  const onDetachUser = useCallback(
    (userId: string) => {
      detachUser({
        userId,
        companyId,
      })
        .then((res) => {
          switch (res.state) {
            case 'partial':
            case 'error':
              toast({
                status: 'error',
                description: res.error.message,
              })
              break
            case 'done':
              toast({
                title: 'Success!',
                description:
                  'User has been detached successfully! Note that they orphaned now.',
              })
              break
            default:
              impossibleState(res)
          }
          return res
        })
        .catch(console.error)
    },
    [toast, detachUser, companyId],
  )

  if (data.length === 0) {
    return (
      <>
        <AttachUserModal />
        <GenericEmpty>
          No users to show. <Button onClick={onOpenAttachUserModal}>Attach user</Button>
        </GenericEmpty>
      </>
    )
  }
  return (
    <>
      <AttachUserModal />
      <Table mt={4} size="sm">
        <Thead>
          <Tr>
            <Th>id</Th>
            <Th>first Name</Th>
            <Th>idp User Id</Th>
            <Th>created Date</Th>
            <Th>email</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {data.map((user) => (
            <Tr key={user.id}>
              <Td>{user.id}</Td>
              <Td>{user.firstName}</Td>
              <Td>{user.idpUserId}</Td>
              <Td>{utcDayJs(user.createdDate).formatYyyyMmDd()}</Td>
              <Td>{user.email}</Td>
              <Td>
                <Button onClick={() => onDetachUser(user.id)}>Detach user</Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td>
              <Button onClick={onOpenAttachUserModal}>Attach user</Button>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </>
  )
}

const BankAccountsTable = ({ data }: { data: BankAccounts }) => {
  if (data.length === 0) {
    return <GenericEmpty />
  }
  return (
    <Table mt={4} size="sm">
      <Thead>
        <Tr>
          <Th>id</Th>
          <Th>balance</Th>
          <Th>created Date</Th>
          <Th>integration source</Th>
          <Th>accounts</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((accounts) => (
          <Tr key={accounts.id}>
            <Td>{accounts.id}</Td>
            <Td>{formatMoney(accounts?.balance ?? 0)}</Td>
            <Td>{utcDayJs(accounts.createdDate).formatYyyyMmDd()}</Td>
            <Td>{accounts.integration.source}</Td>
            <Td>
              <JsonReveal object={accounts.accounts} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const BudgetsTable = ({ data }: { data: Budgets }) => {
  if (data.length === 0) {
    return <GenericEmpty />
  }
  return (
    <Table mt={4} size="sm">
      <Thead>
        <Tr>
          <Th>start Date</Th>
          <Th>updated Date</Th>
          <Th>expected Cash In</Th>
          <Th>expected Cash Out</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((budget) => (
          <Tr key={budget.id}>
            <Td>{utcDayJs(budget.startDate).formatDateTime()}</Td>
            <Td>{utcDayJs(budget.endDate).formatDateTime()}</Td>
            <Td>{formatMoney(budget?.expectedCashIn ?? 0)}</Td>
            <Td>{formatMoney(budget.expectedCashOut ?? 0)}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
