import React from 'react'
import type { SpinnerProps, FlexProps } from '@chakra-ui/react'
import { Flex, Spinner } from '@chakra-ui/react'

export const GenericSpinner = ({
  containerProps,
  ...props
}: SpinnerProps & { containerProps?: FlexProps }) => {
  return (
    <Flex
      w="100%"
      h="100%"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      {...containerProps}
    >
      <Spinner {...props} />
    </Flex>
  )
}
