import type { InputProps, FormLabelProps } from '@chakra-ui/react'
import { FormControl, FormLabel, FormErrorMessage, Input } from '@chakra-ui/react'
import React, { forwardRef } from 'react'

type LabeledInputProps = InputProps & {
  label: string
  errorLabel?: string
  labelProps?: FormLabelProps
  isRequired?: boolean
}

export const LabeledInput = forwardRef<HTMLInputElement, LabeledInputProps>(
  ({ id, label, labelProps, errorLabel, isRequired, isInvalid, ...props }, ref) => {
    return (
      <FormControl
        id={id}
        spacing={2}
        justifyContent="space-between"
        isRequired={isRequired}
        isInvalid={isInvalid}
      >
        <FormLabel {...labelProps}>{label}</FormLabel>
        <Input ref={ref} {...props} />
        <FormErrorMessage>{errorLabel}</FormErrorMessage>
      </FormControl>
    )
  },
)
