import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import type { AuthService as AuthServiceInstance } from '@liveflow-io/service-auth'
import { AUTH_CHANGE_EVENT } from '@liveflow-io/service-auth'

export type AuthContextType<Profile = any> = {
  idToken?: string
  accessToken?: string
  profile?: Profile
  isLoggedIn: boolean
}

export const buildAuthContext = (AuthService: AuthServiceInstance) => {
  const AuthContext = createContext<AuthContextType>({
    isLoggedIn: AuthService.isLoggedIn(),
    idToken: AuthService.getIdToken(),
    accessToken: AuthService.getAccessToken(),
    profile: AuthService.getProfile(),
  })

  const useIdToken = () => useContext(AuthContext).idToken
  const useAccessToken = () => useContext(AuthContext).accessToken
  const useIsLoggedIn = () => useContext(AuthContext).isLoggedIn
  const useProfile = () => useContext(AuthContext).profile
  const useAuth = () => useContext(AuthContext)

  const useListenForAuthChanges = () => {
    const [accessToken, setAccessToken] = useState(AuthService.getAccessToken())
    const [idToken, setIdToken] = useState(AuthService.getIdToken())
    const [profile, setProfile] = useState(AuthService.getProfile())
    const [isLoggedIn, setLoggedIn] = useState(AuthService.isLoggedIn())
    useEffect(() => {
      const callback = () => {
        setAccessToken(AuthService.getAccessToken())
        setIdToken(AuthService.getIdToken())
        setProfile(AuthService.getProfile())
        setLoggedIn(AuthService.isLoggedIn())
      }
      document.addEventListener(AUTH_CHANGE_EVENT, callback)
      return () => {
        document.removeEventListener(AUTH_CHANGE_EVENT, callback)
      }
    }, [])

    return useMemo(
      () => ({
        accessToken,
        idToken,
        profile,
        isLoggedIn,
      }),
      [accessToken, idToken, profile, isLoggedIn],
    )
  }

  const AuthProvider: React.FC = ({ children }) => {
    const authState = useListenForAuthChanges()
    return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  }

  return {
    AuthProvider,
    useIsLoggedIn,
    useAccessToken,
    useAuth,
    useIdToken,
    useProfile,
  }
}
