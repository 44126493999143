import type { FormEventHandler } from 'react'
import { Suspense } from 'react'
import React, { useCallback, useRef, useState } from 'react'
import { Button, Heading, Input, Skeleton, SlideFade, Stack } from '@chakra-ui/react'
import { Search_CompanyDocument } from './document.generated'
import { isValidResult } from '@liveflow-io/utils-common'
import { GenericEmpty, GenericError } from '@liveflow-io/component-common'
import { CompaniesTable } from 'components/CompaniesTable'
import { useQuery } from 'urql'

export const Search = () => {
  const input = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState<string>('')
  const triggerSearch = useCallback<FormEventHandler>((e) => {
    e.preventDefault()
    setSearch(input.current?.value ?? '')
  }, [])
  return (
    <SlideFade in>
      <Stack spacing={4} as="form" onSubmit={triggerSearch}>
        <Heading>Search</Heading>
        <Input
          ref={input}
          placeholder="Input email, name or whatever about the company"
        />
        <Button colorScheme="blue" type="submit">
          Search
        </Button>
        <Suspense fallback={<Skeleton minHeight={400} height="full" />}>
          <SearchResult search={search} />
        </Suspense>
      </Stack>
    </SlideFade>
  )
}

const SearchResult = ({ search }: { search: string }) => {
  const [companiesState] = useQuery({
    query: Search_CompanyDocument,
    pause: search === '',
    variables: {
      input: {
        search,
      },
    },
  })

  // Corner case as query is paused
  if (!companiesState.data) {
    return <GenericEmpty />
  }

  if (!isValidResult(companiesState)) {
    return <GenericError />
  }

  return <CompaniesTable data={companiesState.data.companies} />
}
