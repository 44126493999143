import React, { Suspense } from 'react'
import { Heading, Skeleton, SlideFade, Stack } from '@chakra-ui/react'
import { Companies_CompanyDocument } from './document.generated'
import { isValidResult } from '@liveflow-io/utils-common'
import { GenericError } from '@liveflow-io/component-common'
import { CompaniesTable } from 'components/CompaniesTable'
import { useQuery } from 'urql'

export const Companies = () => {
  return (
    <SlideFade in>
      <Stack spacing={4}>
        <Heading>Companies</Heading>
        <Suspense fallback={<Skeleton minH="400px" h="full" />}>
          <CompaniesResult />
        </Suspense>
      </Stack>
    </SlideFade>
  )
}

const CompaniesResult = () => {
  const [companiesState] = useQuery({
    query: Companies_CompanyDocument,
    variables: { input: {} },
  })

  if (!isValidResult(companiesState)) {
    return <GenericError />
  }
  return <CompaniesTable data={companiesState.data.companies} />
}
