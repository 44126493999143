import { Flex, Heading } from '@chakra-ui/react'

export const NotFound = () => {
  return (
    <Flex justifyContent="center" alignItems="center" w="100vw" h="100vh">
      <Heading as="h1" size="xl">
        Not Found.
      </Heading>
    </Flex>
  )
}
