import { Route, Routes } from 'react-router-dom'
import { SignInPage } from 'pages/SignIn'
import { AuthRedirect as AuthRedirectPage } from 'pages/AuthRedirect'
import { NotFound as NotFoundPage } from 'pages/NotFound'
import { ProtectedRoute } from 'packlets/components'
import { useListenToSystemTheme } from '@liveflow-io/hooks-common'
import { Box } from '@chakra-ui/react'
import { Routes as RoutesConstants } from 'packlets/constants'
import {
  SecuredApp,
  SearchPage,
  CompaniesPage,
  CompanyPage,
  EmailsWhitelistPage,
} from './secured'

export const App = () => {
  useListenToSystemTheme()
  return (
    <Box minHeight="100vh">
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path={RoutesConstants.AUTH_REDIRECT} element={<AuthRedirectPage />} />
        <Route path={RoutesConstants.SIGN_IN} element={<SignInPage />} />
        <ProtectedRoute path="/" element={<SecuredApp />}>
          <ProtectedRoute path={RoutesConstants.SEARCH} element={<SearchPage />} />
          <ProtectedRoute path={RoutesConstants.COMPANIES} element={<CompaniesPage />} />
          <ProtectedRoute
            path={RoutesConstants.EMAILS_WHITELIST}
            element={<EmailsWhitelistPage />}
          />
          <ProtectedRoute
            path={`${RoutesConstants.COMPANY}/:companyId`}
            element={<CompanyPage />}
          />
        </ProtectedRoute>
      </Routes>
    </Box>
  )
}
