import type { ReportHandler } from 'web-vitals'

export const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals')
      .then((it) => {
        const { getCLS, getFID, getFCP, getLCP, getTTFB } = it
        getCLS(onPerfEntry)
        getFID(onPerfEntry)
        getFCP(onPerfEntry)
        getLCP(onPerfEntry)
        getTTFB(onPerfEntry)
        return it
      })
      .catch((error) => console.error(error))
  }
}
