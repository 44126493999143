import { Stack, Button, Flex, Text, Box } from '@chakra-ui/react'
import type { ReactNode } from 'react'
import React from 'react'

export const GenericError = ({
  message,
  button,
  children,
}: {
  message?: ReactNode
  button?: ReactNode
  children?: ReactNode
}) => {
  return (
    <Flex
      w="100%"
      h="100%"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      p={4}
    >
      {children ?? (
        <Stack space={2}>
          {message ?? (
            <Box>
              <Text>
                It seems that something unfortunate has happened. You can try to reload
                the page, or change route back and forth.
              </Text>
              <Text mt={2}>If it repeats, please let us know at support@liveflow.io</Text>
            </Box>
          )}

          {button ?? (
            <Button colorScheme="red" onClick={() => document.location.reload()}>
              Refresh page
            </Button>
          )}
        </Stack>
      )}
    </Flex>
  )
}
