import type { ConfigType } from 'dayjs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import weekdays from 'dayjs/plugin/weekday'
import quarter from 'dayjs/plugin/quarterOfYear'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { isNotNullish, isNotEmptyOrNullish } from './utils'

type HalfUnitType = dayjs.UnitType | 'half'

declare module 'dayjs' {
  interface Dayjs {
    formatYyyyMm(): string
    formatYyyyMmDd(): string
    formatDateTime(): string
    formatRfc3339(): string
    toGraphQLDateTime(): string
    toGraphQLDate(): string
    toGraphQLTime(): string

    half(): number

    half(half: number): dayjs.Dayjs

    add(value: number, unit: HalfUnitType): dayjs.Dayjs

    subtract(value: number, unit: HalfUnitType): dayjs.Dayjs

    startOf(unit: HalfUnitType): dayjs.Dayjs

    endOf(unit: HalfUnitType): dayjs.Dayjs

    diff(date: dayjs.ConfigType, unit?: HalfUnitType, float?: boolean): number
  }
  /**
   * Parses YYYY-MM to DayJS UTC date. Requires utc plugin. Be aware of parsing it to local date
   * @param config
   */
  export function parseYyyyMm(config: dayjs.ConfigType): dayjs.Dayjs
}

export const halfPlugin: dayjs.PluginFunc = (_, c) => {
  const proto = c.prototype

  // @ts-expect-error
  proto.half = function halfFn(half?: number): dayjs.Dayjs | number {
    if (isNotNullish(half)) {
      return this.month((this.month() % 6) + half * 6)
    }

    return Math.ceil((this.month() + 1) / 6)
  }

  const oldAdd = proto.add

  // @ts-expect-error
  proto.add = function add(number: number, units: HalfUnitType, ...args: any[]) {
    number = Number(number) // eslint-disable-line no-param-reassign
    if (units === 'half') {
      return this.add(number * 6, 'month')
    }

    // @ts-expect-error
    return oldAdd.apply(this, [number, units, ...args])
  }

  const oldSubtract = proto.subtract

  // @ts-expect-error
  proto.subtract = function subtract(
    number: number,
    units: HalfUnitType,
    ...args: any[]
  ) {
    number = Number(number) // eslint-disable-line no-param-reassign
    if (units === 'half') {
      return this.subtract(number * 6, 'month')
    }

    // @ts-expect-error
    return oldSubtract.apply(this, [number, units, ...args])
  }

  const oldStartOf = proto.startOf

  // @ts-expect-error
  proto.startOf = function startOf(units: HalfUnitType, ...rest: any[]) {
    if (units === 'half') {
      return this.month((this.half() - 1) * 6).startOf('month')
    }

    // @ts-expect-error
    return oldStartOf.apply(this, [units, ...rest])
  }

  const oldEndOf = proto.endOf

  // @ts-expect-error
  proto.endOf = function endOf(units: HalfUnitType, ...rest: any[]) {
    if (units === 'half') {
      return this.month(this.half() * 6 - 1).endOf('month')
    }

    // @ts-expect-error
    return oldEndOf.apply(this, [units, ...rest])
  }

  const oldDiff = proto.diff
  // @ts-expect-error
  proto.diff = function diff(
    config: ConfigType,
    units: HalfUnitType,
    float: boolean,
    ...rest: any[]
  ) {
    if (units === 'half') {
      // @ts-expect-error
      const theDiff = oldDiff.apply(this, [config, 'month', float, ...rest]) / 6
      return float
        ? theDiff
        : theDiff > 0
        ? Math.floor(theDiff)
        : Object.is(Math.ceil(theDiff), -0)
        ? 0
        : Math.ceil(theDiff)
    }

    // @ts-expect-error
    return oldDiff.apply(this, [config, units, float, ...rest])
  }

  const oldFormat = proto.format

  proto.format = function format(formatStr?: string) {
    const _this = this
    const str = (isNotEmptyOrNullish(formatStr) && formatStr) || 'YYYY-MM-DDTHH:mm:ssZ'
    const result = str.replace(/\[([^\]]+)]|P/g, (match) => {
      switch (match) {
        case 'P': {
          return _this.half().toString()
        }
        default:
          return match
      }
    })
    return oldFormat.bind(this)(result)
  }
}

export const commonDateFunctions: dayjs.PluginFunc = (_, dayjsClass, dayJsFactory) => {
  const format = dayjsClass.prototype.format
  dayjsClass.prototype.formatYyyyMm = function formatYyyyMm() {
    return format.call(this, 'YYYY-MM')
  }

  dayjsClass.prototype.formatYyyyMmDd = function formatYyyyMmDd() {
    return format.call(this, 'YYYY-MM-DD')
  }

  dayjsClass.prototype.toGraphQLDate = dayjsClass.prototype.formatYyyyMmDd

  dayjsClass.prototype.formatDateTime = function formatDateTime() {
    return format.call(this, 'YYYY-MM-DD HH:mm:ssZ')
  }

  dayjsClass.prototype.formatRfc3339 = dayjsClass.prototype.toISOString
  dayjsClass.prototype.toGraphQLDateTime = dayjsClass.prototype.toISOString

  dayjsClass.prototype.toGraphQLTime = function toGraphQLTime() {
    return format.call(this, 'HH:mm:ssZ')
  }

  dayJsFactory.parseYyyyMm = function parseYyyyMm(date: dayjs.ConfigType) {
    return dayJsFactory.utc(date, 'YYYY-MM')
  }
}

dayjs.extend(utc)
dayjs.extend(isBetween)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(commonDateFunctions)
dayjs.extend(weekdays)
dayjs.extend(quarter)
dayjs.extend(advancedFormat)
dayjs.extend(halfPlugin)

export const utcDayJs = (
  config?: Parameters<typeof dayjs.utc>[0],
  format?: Parameters<typeof dayjs.utc>[1],
) => {
  return dayjs.utc(config, format)
}

export type GenerateRangesUnits = 'month' | 'quarter' | 'year' | 'half'

export function generateRangesByUnit({
  startDate,
  endDate,
  unit,
  limit = 999,
}: {
  endDate: dayjs.Dayjs
  startDate: dayjs.Dayjs
  unit: GenerateRangesUnits
  limit?: number
}) {
  if (endDate.isBefore(startDate)) {
    throw new Error('End date should be after start date')
  }
  const inTwoDifferentYears = startDate.year() !== endDate.year()
  const QUARTER_FORMAT = inTwoDifferentYears ? 'YYYY [Q]Q' : '[Q]Q'
  const YEAR_FORMAT = 'YYYY'
  const HALF_FORMAT = inTwoDifferentYears ? 'YYYY [H]P' : '[H]P'
  const MONTH_FORMAT = inTwoDifferentYears ? 'MMM YYYY' : 'MMM'
  const FORMAT = {
    month: MONTH_FORMAT,
    quarter: QUARTER_FORMAT,
    year: YEAR_FORMAT,
    half: HALF_FORMAT,
  }[unit]

  if (limit < 1) {
    throw new Error('Limit cannot be below 1!')
  }
  let result = {}
  // @ts-expect-error
  let cursor = endDate.subtract(1, unit).endOf(unit)
  if (cursor.isBefore(startDate)) {
    const isInSameUnit =
      startDate[unit]() === endDate[unit]() && startDate.year() === endDate.year()
    if (isInSameUnit) {
      return { [startDate.format(FORMAT)]: [startDate, endDate] }
    }
    return {
      // @ts-expect-error
      [endDate.format(FORMAT)]: [endDate.startOf(unit), endDate],
      ...(limit === 1
        ? {}
        : {
            // @ts-expect-error
            [startDate.format(FORMAT)]: [startDate, startDate.endOf(unit)],
          }),
    }
  }
  // @ts-expect-error
  const prependLastOne = endDate.diff(startDate, unit) - (limit - 2) <= 0
  // if prepend, then we have to start from 3 as if we have 2 elements already otherwise we start from 2 as if we have only 1
  let i = prependLastOne ? 3 : 2
  // @ts-expect-error
  while (cursor.isAfter(startDate.endOf(unit)) && i < limit) {
    result = Object.assign(result, {
      // @ts-expect-error
      [cursor.format(FORMAT)]: [cursor.startOf(unit), cursor.endOf(unit)],
    })
    i += 1
    // @ts-expect-error
    cursor = cursor.subtract(1, unit)
  }
  return {
    // @ts-expect-error
    [endDate.format(FORMAT)]: [endDate.startOf(unit), endDate],
    ...result,
    ...(prependLastOne
      ? // @ts-expect-error
        { [startDate.format(FORMAT)]: [startDate, startDate.endOf(unit)] }
      : {}),
  }
}

// eslint-disable-next-line import/no-default-export
export default dayjs
