import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'

// Required to initialize AuthService instance
// eslint-disable-next-line no-restricted-imports
import 'services/AuthService'
import { UrqlProvider, AuthProvider } from 'providers'
import { Provider as JotaiProvider } from 'jotai'
import { App } from './pages'
import { reportWebVitals } from './reportWebVitals'

const customTheme = extendTheme({
  colors: {
    black: '#333333',
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light',
  },
})

ReactDOM.render(
  <StrictMode>
    <ChakraProvider theme={customTheme}>
      <AuthProvider>
        <Router>
          <UrqlProvider>
            <JotaiProvider>
              <App />
            </JotaiProvider>
          </UrqlProvider>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  </StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
