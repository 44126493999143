import { buildAuthContext } from '@liveflow-io/provider-auth'
import { AuthService } from 'services'

export const {
  AuthProvider,
  useIsLoggedIn,
  useAccessToken,
  useAuth,
  useIdToken,
  useProfile,
} = buildAuthContext(AuthService)
export { UrqlProvider } from './UrqlProvider'
